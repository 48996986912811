import axios from 'axios'
import store from '../store/store'
import AuthService from '../services/AuthService'

export default class APIService {  
  
  static getDataFromApi = async (url) => {
    try {
      const data = await axios.get(url, { withCredentials : true })
      return data.data.result
    } catch(err) {
      if(err.response.status === 401) {
        store.commit("dialog/setDialog", { 
          header: 'Sessão Expirada',
          message: err.response.data.error, 
          color: 'white',
          persistent: true, 
          buttons: [
            {
              color: 'red',
              text: 'Sair',
              handler: async () => {
                store.commit("loadingoverlay/setLoadingOverlay",{ show: true, opacity: 0.5, message: 'Saindo'})
                await AuthService.logout()
              }
            }
          ]        
        })
      } else {
        store.commit("snackbar/setSnack",{ message: err.response.data.error, color: 'error', duration: 4000})
      }
      return false;
    }
  } 
}


