<template>
  <v-container fluid class="container pa-0">
    <v-toolbar
        flat
        color="blue"
        height="56px"
        fixed
    >
      <v-img
          lazy-src="../assets/logo-eme4.png"
          max-width="90px"
          src="../assets/logo-eme4.png"
      ></v-img>
      <v-spacer></v-spacer>
      <v-btn
          color="transparent"
          elevation="0"
          class="px-2"
          @click="logoutDialog()"
      >
        <span class="white--text pr-2">{{ user.chave }}</span>
        <i class="fas fa-sign-out-alt white--text"></i>
      </v-btn>
    </v-toolbar>
    <div :class="$vuetify.breakpoint.mdAndUp ? resize ? 'chat-maximized' : 'chat-minimized' : 'chat-mobile'"
         class="chat-container">
      <div class="flex-container">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="card-suggestions"
          :class="showSuggestions ? '' : 'hidde-card-suggestions'"
        >
          <div class="card-suggestions-inside">
            <v-list dense class="elevation-0">
              <v-list-item-group>
                <v-list-item
                    v-for="(item, i) in modules"
                    :key="i"
                    @click="sendMessage(item.text); showSuggestions = false"
                    class="suggestions-list"
                >
                  <v-list-item-content style="box-shadow: none !important;">
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
        <v-card
            transition="scale-transition"
            origin="center center"
            :rounded="$vuetify.breakpoint.smAndDown ? '0' : '3'"
            class="chat-card"
            :class="$vuetify.breakpoint.smAndDown ? 'chat-mobile' : ''"
        >
          <v-card-title
              class="pa-2"
          >
            <v-btn
                icon
                @click="showSuggestions = !showSuggestions"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
              <i class="fas fa-chevron-right" v-if="showSuggestions"></i>
              <i class="fas fa-chevron-left" v-if="!showSuggestions"></i>
            </v-btn>
            <v-img
                lazy-src="../assets/logo-lia.png"
                max-height="30" max-width="60"
                src="../assets/logo-lia.png"
                class="mx-3"
            ></v-img>

            <v-spacer></v-spacer>

            <v-menu
                open-on-hover
                bottom
                origin="center center"
                offset-y
                v-if="$vuetify.breakpoint.smAndDown"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="blue"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    small
                >
                  Módulos
                  <v-icon
                      right
                      dark
                  >
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list
                  class="py-0"
                  dense
              >
                <v-list-item
                    v-for="(module, index) in modules"
                    :key="index"
                    @click="sendMessage(module.text);"
                    flat
                >
                  <v-list-item-title>{{ module.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
                icon
                @click="resetChat()"
                class="ml-3"
            >
              <i class="fas fa-redo"></i>
            </v-btn>
            <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                icon
                @click="resize = !resize"
            >
              <i v-if="resize == false" class="fas fa-expand"></i>
              <i v-if="resize == true" class="fas fa-compress"></i>
            </v-btn>
          </v-card-title>

          <v-card-text
              v-chat-scroll="{smooth: true}"
              class="flex-grow-1 overflow-y-auto text-card pa-2"
              :class="$vuetify.breakpoint.mdAndUp ? '' : 'text-card-mobile'"
          >
            <template>
              <div
                  v-for="(msg,index) in messages" :key="index"
                  :class="{ 'd-flex flex-row-reverse': msg.me }"
              >
                <v-menu offset-y>
                  <template v-slot:activator={}>
                    <v-chip
                        :color="msg.me ? 'lightBlue' : 'lightGrey'"
                        class="px-3 py-0 mb-2 message-chip"
                    >
                      <v-list-item-content
                          class="px-0 py-3"
                      >
                        <v-row align="center">
                          <v-col
                              cols="1"
                              v-if="!msg.me"
                              class="py-2"
                          >
                            <v-avatar size="35px">
                              <img
                                  src="../assets/logo_lia_03.png"
                                  alt="Lia"
                              >
                            </v-avatar>
                          </v-col>
                          <v-col class="py-2">
                            <span v-for="(text, index) in msg.content" :key="index"> {{ text }} </span>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-chip>
                  </template>
                </v-menu>

                <v-data-table
                    no-data-text="Nenhum dado encontrado!"
                    dense
                    disable-sort
                    hide-default-footer
                    :items-per-page="msg.datatable.data.length"
                    :mobile-breakpoint="0"
                    v-if="!msg.me && msg.datatable"
                    :headers="msg.datatable.headers"
                    :items="msg.datatable.data"
                    class="mb-3"
                >
                  <template v-slot:[`item.valor_bruto`]="{ item }">
                    {{ currencyFormat(item.valor_bruto) }}
                  </template>
                  <template v-slot:[`item.valor_liquido`]="{ item }">
                    {{ currencyFormat(item.valor_liquido) }}
                  </template>
                  <template v-slot:[`item.valor_total`]="{ item }">
                    {{ currencyFormat(item.valor_total) }}
                  </template>
                  <template v-slot:[`item.valor_receita`]="{ item }">
                    {{ currencyFormat(item.valor_receita) }}
                  </template>
                  <template v-slot:[`item.valor_despesa`]="{ item }">
                    {{ currencyFormat(item.valor_despesa) }}
                  </template>
                  <template v-slot:[`item.valor_saldo`]="{ item }">
                    {{ currencyFormat(item.valor_saldo) }}
                  </template>
                  <template v-slot:[`item.media_dias_uteis`]="{ item }">
                    {{ currencyFormat(item.media_dias_uteis) }}
                  </template>
                  <template v-if="msg.datatable.totals && msg.datatable.data.length > 1"
                            v-slot:[`body.append`]="{ headers }">
                    <tr class="row-footer text-start">
                      <td v-for="(header,i) in headers" :key="i">
                        <div v-if="i == 0">
                          <strong>Total</strong>
                        </div>
                        <div v-if="header.value == 'valor_bruto'">
                          <strong>{{ currencyFormat(msg.datatable.totals.total_bruto.value) }}</strong>
                        </div>

                        <div v-if="header.value == 'valor_liquido'">
                          <strong>{{ currencyFormat(msg.datatable.totals.total_liquido.value) }}</strong>
                        </div>

                        <div v-if="header.value == 'valor_total'">
                          <strong>{{ currencyFormat(msg.datatable.totals.total.value) }}</strong>
                        </div>
                        <div v-if="header.value == 'valor_receita'">
                          <strong>{{ currencyFormat(msg.datatable.totals.total_receita.value) }}</strong>
                        </div>
                        <div v-if="header.value == 'valor_despesa'">
                          <strong>{{ currencyFormat(msg.datatable.totals.total_despesa.value) }}</strong>
                        </div>
                        <div v-if="header.value == 'valor_saldo'">
                          <strong>{{ currencyFormat(msg.datatable.totals.total_saldo.value) }}</strong>
                        </div>
                        <div v-if="header.value == 'quantidade'">
                          <strong>{{ msg.datatable.totals.quantidade.value }}</strong>
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-col
                    v-if="!msg.me && msg.buttons"
                    class="pa-0"
                >
                  <v-btn
                      @click="sendMessage(button.text);"
                      v-for="(button, iButtons) in msg.buttons"
                      :key="iButtons"
                      :value="button.text"
                      small
                      class="mx-1 pa-1 mb-2 elevation-0"
                      color="blue"
                      :disabled="msg.disableButtons"
                  >
                    <span>{{ button.text }}</span>
                  </v-btn>
                </v-col>
                <v-col
                    v-if="!msg.me && msg.suggestions"
                    class="pa-0"
                >
                  <v-btn
                      @click="sendMessage(button.text);"
                      v-for="(button, iButton) in msg.suggestions"
                      :key="iButton"
                      :value="button.text"
                      small
                      class="mx-1 pa-1 mb-2 elevation-0"
                      color="blue"
                      :disabled="msg.disableButtons"
                  >
                    <span>{{ button.text }}</span>
                  </v-btn>
                </v-col>
              </div>
            </template>
          </v-card-text>
          <v-progress-linear
              absolute
              indeterminate
              color="blue"
              :active="loading"
              :class="$vuetify.breakpoint.mdAndUp ? 'progress-line' : ''"
          ></v-progress-linear>

          <v-card-text
              class="flex-shrink-1 pa-2"
          >
            <v-text-field
                label="Mensagem"
                type="text"
                no-details
                append-outer-icon="mdi-send"
                solo
                elevation={0}
                :disabled="loading"
                clearable
                color="blue"
                v-model="message"
                @keyup.enter="sendMessage()"
                @click:append-outer="sendMessage()"
                hide-details
            />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import WatsonService from '../services/WatsonService'
import UserService from '../services/UserService'
import AuthService from '../services/AuthService'
import APIService from '../services/APIService'
import {mapMutations} from "vuex";

export default {
  name: 'HomeComponent',
  computed: {},
  data: () => ({
    message: '',
    messages: [],
    modules: [],
    payload: {},
    loading: false,
    overlay: {
      show: false,
      message: ''
    },
    user: {},
    dialog: false,
    apiResponse: [],
    resize: false,
    showSuggestions: true
  }),
  methods: {
    async sendMessage(message) {
      this.disableButtons(this.messages.length - 1);

      if (this.message || message) {
        this.messages.push({
          content: [this.message ? this.message : message],
          me: true,
        })

        this.loading = true
        this.payload.input.text = this.message ? this.message : message
        this.message = ''
        this.payload = await WatsonService.callAssistant(this.payload)

        if (this.payload.context.chatbot_url_request) {
          this.apiResponse = await APIService.getDataFromApi(this.payload.context.chatbot_url_request.url)
        }

        this.messages.push({
          content: this.payload.output.text,
          me: false,
          datatable: this.apiResponse.data ? this.apiResponse : null,
          buttons: this.payload.context.chatbot_buttons,
          suggestions: this.payload.context.chatbot_suggestions,
          disableButtons: false
        })

        this.apiResponse = []
        this.loading = false
      }
    },
    disableButtons(index) {
      this.messages[index].disableButtons = true;
    },
    resetChat() {
      this.setLoadingOverlay({
        show: true,
        opacity: 0.6,
        message: 'Carregando',
      })
      this.messages = []
      this.initChat()
    },
    async initChat() {
      this.payload = await WatsonService.init()

      if (this.payload) {
        this.messages.push({
          content: this.payload.output.text,
          me: false,
          buttons: this.payload.context.chatbot_buttons,
        })
      }

      if (this.payload.context && this.payload.context.chatbot_suggestions) {
        this.modules = this.payload.context.chatbot_suggestions;
        this.showSuggestions = true;
      }
      this.removeLoadingOverlay()
    },
    currencyFormat(val) {
      val = parseFloat(val)
      return val.toLocaleString('pt-BR', {minimumFractionDigits: 2, style: 'currency', currency: 'BRL'})
    },
    logoutDialog() {
      this.setDialog({
        header: 'Sair',
        message: 'Deseja realmente sair?',
        color: 'white',
        buttons: [
          {
            color: 'blue',
            text: 'Cancelar',
          },
          {
            color: 'red',
            text: 'Sair',
            handler: async () => {
              this.setLoadingOverlay({
                show: true,
                opacity: 0.5,
                message: 'Saindo',
              })

              await AuthService.logout()
            }
          }
        ]
      })
    },
    ...mapMutations("snackbar", ["setSnack"]),
    ...mapMutations("dialog", ["setDialog"]),
    ...mapMutations('loadingoverlay', ['setLoadingOverlay']),
    ...mapMutations('loadingoverlay', ['removeLoadingOverlay']),
  },
  created: function () {
    this.user = UserService.getUser()
    this.initChat()
  },

}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebebeb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0afaf;
}

html, body {
  height: 100%
}

.container {
  height: 100vh;
  background-image: url('../assets/chat_eme4_lia_fundo_branco.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.flex-container {
  position: relative;
  display: flex;
  height: 100%
}

.flex-container-hidden-suggestions > .chat-card {
  margin-left: 0px !important;
}

.flex-container-hidden-suggestions > .card-suggestions {
  left: 0px !important;
  position: relative;
  margin-right: -250px;
}

.hidde-card-suggestions {
  left: 270px !important
}

.chat-container {
  position: absolute;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.chat-minimized {
  bottom: 25px;
  right: 25px;
  width: 800px;
  height: calc(100vh - 200px);
}

.chat-maximized {
  bottom: 25px;
  right: 25px;
  margin-top: 25px;
  margin-left: 25px;
  width: calc(100vw - 50px);
  height: calc(100vh - 106px);
}

.chat-mobile {
  position: initial !important;
  height: calc(100vh - 56px);
  margin: 0 !important;
}

.text-card-mobile {
  max-width: 100vw;
  height: calc(100% - 117px);
  margin: 0 !important;
}

.card-suggestions {
  position: absolute;
  width: 270px;
  left: 10px;
  margin-top: 50px;
  height: 400px;
  background-color: #f2f2f2;
  padding: 5px;
  border-radius: 5px;
  margin-right: -10px;
  border: 1px solid #024879;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.card-suggestions-inside {
  background-color: #ffffff;
  height: 100%;
  border-radius: 5px;
  padding: 10px
}

.message-chip {
  height: auto !important;
  white-space: normal !important;
  max-width: 90% !important;
  font-size: 13px !important;
}

.suggestions-list {
  border-radius: 5px;
  margin: 5px;
  background-color: #024879;
  height: 25px;
  color: white !important;
}

.chat-card {
  margin-left: 270px;
  right: 0;
  bottom: 0;
  width: 100%;
}

.v-list-item:hover {
  background-color: #e3e3e3 !important;
  color: black !important;
  cursor: pointer;
}

.chat-card {
  background-color: #f2f2f2 !important;
  border: 1px solid #024879 !important;
}

.text-card {
  background-color: #fff;
  margin: 0 8px 0 8px;
  left: 0 !important;
  right: 0 !important;
  width: auto !important;
  border-radius: 6px 6px 0 0;
  height: calc(100% - 118px);
}

.text-card > div > .col button {
  padding: 5px 10px !important;
  background-color: #4a4f72;
  color: #ddd !important;
  font-size: 12px;
  border-radius: 3px;
  text-decoration: none;
  letter-spacing: normal;
  text-transform: none;
}

.progress-line {
  height: 0px;
  margin-left: 8px;
  margin-right: 9px;
  left: 0px;
  right: 0px;
  width: auto;
}

/* ajustes da tabela */
.v-data-table {
  max-width: none !important;
  border-spacing: 0 !important;
  border-collapse: collapse !important;
}

.v-data-table__wrapper {
  display: block !important;
}

.v-data-table__wrapper table tr th,
.v-data-table__wrapper table tr td {
  font-size: 12px !important;
}

.v-data-table__wrapper table tr th,
.v-data-table__wrapper table tr td,
.v-data-table__wrapper table tbody tr td {
  padding: 4px 4px !important;
  height: auto !important;
  line-height: normal;
}

.v-data-table__wrapper table tbody tr td {
  color: #515151 !important;
  text-align: left !important;
}

.v-data-table__wrapper table tbody tr:nth-child(odd):not(.row-footer) td {
  background-color: #ecf0f1 !important;
}

.v-data-table__wrapper table tbody tr:nth-child(even):not(.row-footer) td {
  background-color: #cbdadf !important;
}

.v-data-table__wrapper table thead tr th,
.v-data-table__wrapper table tfoot tr td,
.v-data-table__wrapper table tr.row-footer td {
  background-color: #024879 !important;
  color: #d1d1d1 !important;
  padding: 4px !important;
}
</style>
