<template>
  <home-component />
</template>

<script>
  import HomeComponent from '../components/HomeComponent'

  export default {
    name: 'Home',

    components: {
      HomeComponent,
    },
  }
</script>
